<template>
  <div class="kt_content_container">
    <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
      <!--begin::Icon-->
      <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->
      <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
          <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
          <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
        </svg>
      </span>
      <!--end::Svg Icon-->
      <!--end::Icon-->
      <!--begin::Wrapper-->
      <div class="d-flex flex-stack flex-grow-1">
        <!--begin::Content-->
        <div class="fw-semibold">
          <div class="fs-6 text-gray-700">
            <strong class="me-1">Warning!</strong>By editing the configuration settings the page will be force reloaded. Please ensure you're absolutely certain before proceeding.
          </div>
        </div>
        <!--end::Content-->
      </div>
      <!--end::Wrapper-->
    </div>
    <div class="card mb-5 mb-xl-10" v-loading="configurationLoading">
      <div class="card-body border-top p-9" v-if="configurationData">
        <el-form :model="configurationData" ref="formRef" class="w-100">
          <div class="col-md-12 mb-5">
            <table class="
                table
                align-middle
                table-row-dashed
                fs-6
                dataTable
                no-footer
              ">
              <thead>
                <tr class="
                    text-start text-gray-800
                    fw-bolder
                    fs-7
                    text-uppercase
                    gs-0
                  ">
                  <th>Description</th>
                  <th>Setting</th>
                </tr>
              </thead>
              <tbody class="fw-bold text-gray-600">
                <tr v-for="(configuration, index) in configurationData" :key="index" v-bind:id="'row_' + index" align="center">
                  <td align="left">
                    {{ configuration.name }}<br />
                    <span class="text-gray-400">
                      {{ configuration.help }}
                    </span>
                  </td>
                  <td>
                    <el-form-item v-if="configuration.element_type == 'number'">
                      <el-input v-model="configuration.set_value" :tabindex="index" size="large" type="number" min="0" />
                    </el-form-item>
                    <el-form-item v-else-if="configuration.element_type == 'text'">
                      <el-input v-model="configuration.set_value" :tabindex="index" size="large" type="text" />
                    </el-form-item>
                    <el-form-item v-else-if="configuration.element_type == 'color'">
                        <el-color-picker v-model="configuration.set_value" />
                    </el-form-item>
                    <el-form-item v-else-if="configuration.element_type == 'dropdown'">
                      <el-select v-model="configuration.set_value" :tabindex="index" size="large" class="setting-select">
                        <el-option :label="val.name" :value="val.value" :key="key" v-for="(val, key) in configuration.values"></el-option>
                      </el-select>
                    </el-form-item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-12 mb-5" v-if="permission.isEditAllowed">
            <button type="button" class="btn btn-primary me-2" :disabled="loading" @click="submitForm(formRef, 'save')">
              {{ generalConstants.SAVE
              }}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </button>
            <button type="button" class="btn btn-secondary me-2" @click="resetForm()">
              {{ generalConstants.RESET
              }}<span v-if="reset" class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script >
import { ref, reactive, onMounted, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { formatText } from "@/core/helpers/common";
import { notificationFire } from "@/composable/notification.js";
import { useRoute, useRouter } from "vue-router";
import globalConstant from "@/core/data/globalConstant.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import {
  loggedInUserRoles,
  loggedInUserPermissions,
} from "@/composable/get-roles.js";
import useEventBus from "../composable/useEventBus";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";

export default {
  name: "dashboard",
  components: {
    Datatable,
  },
  setup() {
    const { onEvent, emitEvent } = useEventBus();
    const route = useRoute();
    const router = useRouter();
    const configurationLoading = ref(false);
    const generalConstants = globalConstant.general;
    const configurationData = ref([]);
    const initConfigurationData = ref([]);
    const formRef = ref(null);
    const loading = ref(false);
    const reset = ref(false);
    const hidden = ref(false);

    //Get click event of toolbar buttons
    onEvent("actionName", (actionName) => {
      if (actionName == "save") {
        submitForm(formRef, "save", true);
      } else if (actionName == "reset") {
        resetForm();
      }
    });

    const permission = reactive({
      isViewAllowed: false,
      isEditAllowed: false
    });

    const resetForm = () => {
      configurationData.value.map(function (element,index) {
        if(element.default_value != ""){
          element.set_value = element.default_value;
        }else{
          element.set_value = element.oldValue;
        }
      });
    }

    //Get configuration setting
    const getConfigurationSetting = async () => {
      configurationLoading.value = true;
      await ApiService.query("configuration-setting")
        .then(({ data }) => {
          if (data.data) {
            data.data.map(function (element) {
              if (
                element.default_value != element.set_value &&
                hidden.value == false
              ) {
                hidden.value = true;
              }
              element.oldValue = element.set_value;
            });
            configurationData.value = data.data;
            initConfigurationData.value = data.data;
          }
          configurationLoading.value = false;
        })
        .catch((error) => {
          //Toast the error here
          configurationData.value = [];
          configurationLoading.value = false;
          hidden.value = false;
        });
    };

    // Submit Data
    const submitForm = async (
      formEl,
      btnName,
      isToolbarButtonsSubmitAction = false
    ) => {
      formEl = isToolbarButtonsSubmitAction ? formEl.value : formEl;
      if (!formEl) return;
      isToolbarButtonsSubmitAction
        ? emitEvent("loader", { save_loader: true, action: btnName })
        : btnName == "save"
        ? (loading.value = true)
        : (reset.value = true);
      await formEl.validate((valid) => {
        let formData = [];
        if (btnName == "save") {
          configurationData.value.forEach((obj) => {
            formData.push({
              configuration_setting_id: obj.configuration_setting_id,
              configuration_master_id: obj.configuration_master_id,
              user_id: obj.user_id,
              value: obj.set_value,
            });
          });
        } else if (btnName == "reset") {
          formData = { action: "reset" };
        }
        if (formData.length > 0 || btnName == "reset") {
          ApiService.post("configuration-setting", formData)
            .then(({ data }) => {
              if (data) {
                notificationFire(data.message, generalConstants.NOTIFY_SUCCESS);
                if (btnName == "save") {
                  for (let i = 0; i < configurationData.value.length; i++) {
                    if (
                      data.data.config[i] != undefined &&
                      configurationData.value[i].configuration_master_id ==
                        data.data.config[i].configuration_master_id
                    ) {
                      configurationData.value[i].configuration_setting_id =
                        data.data.config[i].configuration_setting_id;
                    }
                  }
                  hidden.value = true;
                  generateHeaderToolbarButtons();
                  isToolbarButtonsSubmitAction
                    ? emitEvent("loader", {
                        save_loader: false,
                        action: btnName,
                      })
                    : (loading.value = false);

                  const siteConfig = JSON.parse(
                    window.localStorage.getItem("site_config")
                  );
                  siteConfig.per_page = data.data.per_page;
                  localStorage.setItem(
                    "site_config",
                    JSON.stringify(siteConfig)
                  );
                } else if (btnName == "reset") {
                  hidden.value = false;
                  isToolbarButtonsSubmitAction
                    ? emitEvent("loader", {
                        save_loader: false,
                        action: btnName,
                      })
                    : (reset.value = false);
                  getConfigurationSetting();
                }
                window.location.reload();
              }
            })
            .catch((error) => {
              if (isToolbarButtonsSubmitAction)
                emitEvent("loader", { save_loader: false, action: btnName });
              else if (btnName == "save") loading.value = false;
              else if (btnName == "reset") reset.value = false;
              let message = "";
              if (typeof error.response !== "undefined") {
                message = error.response.data.message;
              } else if (typeof error.message !== "undefined") {
                message = error.message;
              } else {
                message = generalConstants.NOTIFY_WRONG_MESSAGE;
              }
              notificationFire(message, generalConstants.NOTIFY_ERROR);
            });
        } else {
          isToolbarButtonsSubmitAction
            ? emitEvent("loader", { save_loader: false, action: btnName })
            : (loading.value = false);
          notificationFire(
            "Please update configuration data first.",
            generalConstants.NOTIFY_ERROR
          );
        }
      });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons() {
      return setCurrentPageActionButtons([
        {
          name: generalConstants.SAVE,
          action: "save",
          class: "btn-primary me-3",
          isLoader: false,
          isAllowed: permission.isEditAllowed
        },
        {
          name: generalConstants.RESET,
          action: "reset",
          class: "btn-secondary me-3",
          isLoader: false,
          isAllowed: permission.isEditAllowed
        },
      ]);
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [
        { name: formatText(route.name), active: true },
      ]);
      generateHeaderToolbarButtons();
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("readConfigurationSetting") || role == "Super Admin")
        permission.isViewAllowed = true;
      if (per.includes("updateConfigurationSetting") || role == "Super Admin")
        permission.isEditAllowed = true;
      await getConfigurationSetting();
    });

    onUpdated(async () => {
      generateHeaderToolbarButtons();
    });

    return {
      router,
      permission,
      configurationData,
      generalConstants,
      configurationLoading,
      formRef,
      loading,
      reset,
      submitForm,
      hidden,
      resetForm,
    };
  },
};
</script>
<style scoped>
.setting-select {
  width: 100%;
}
</style>